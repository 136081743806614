<template>
  <div>
    <confirm-dialog @confirm="confirmDialog" />
    <br />
    <v-data-table
      :headers="headers"
      :items="representantesTerritoriais"
      sort-by="regiao.sigla"
      class="border"
      :loading="loading"
    >
      <template v-slot:top v-if="!loading">
        <v-toolbar flat color="white">
          <v-toolbar-title
            >Regiões Representadas - {{ representanteName }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-icon
            v-if="canList"
            title="Atualizar"
            medium
            @click="getRepresentantesTerritoriais()"
            >mdi-refresh</v-icon
          >
          <v-spacer />
          <v-btn
            v-if="canCreate"
            color="primary"
            dark
            class="mb-2"
            @click="$emit('cadastrar', 'cadastrar')"
            >Adicionar Regiões</v-btn
          >
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="canUpdate"
          title="Editar Representação"
          small
          class="mr-2"
          color="black"
          @click="$emit('editar', item)"
          >mdi-square-edit-outline</v-icon
        >
        <v-icon
          v-if="canDelete"
          title="Remover Representação"
          small
          color="black"
          @click="deleteItem(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'ListarRepresentanteTerritorial',

  props: {
    representantePublicID: String,
  },

  data: function () {
    return {
      confirmDialogItem: {},
      loading: true,

      representante: {},

      headers: [
        { text: 'Região', value: 'regiao.sigla' },
        { text: 'Tipo de Frete', value: 'tipo_frete' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
    }
  },

  components: { ConfirmDialog },

  computed: {
    ...mapState('patio', ['representantesTerritoriais']),

    permission() {
      return Sequences.Representantes.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    representanteName: function () {
      if (this.representante.colaborador) {
        return this.representante.colaborador.user.name
      }

      return ''
    },
  },

  created: async function () {
    if (this.canList) {
      this.loading = true

      await this.getRepresentantesTerritoriais()

      try {
        this.representante = await this.getRepresentante(
          this.representantePublicID
        )
      } catch (e) {
        this.errorMessage(e.response.data)
      }

      this.loading = false
    }
  },

  methods: {
    ...mapActions('patio', [
      'getRepresentante',
      'getRepresentantesTerritoriaisByRepresentantePublicID',
      'removerRepresentanteTerritorial',
    ]),
    ...mapActions('template', [
      'errorMessage',
      'successMessage',
      'setDialogMessage',
    ]),

    deleteItem: function (item) {
      this.confirmDialogItem = item

      this.setDialogMessage({
        title: 'Excluir Representação',
        message: `Deseja realmente remover a região ${item.regiao.sigla} do representante ${this.representanteName}?`,
      })
    },

    confirmDialog: async function () {
      try {
        await this.removerRepresentanteTerritorial(this.confirmDialogItem)
        this.successMessage('Representação excluída com sucesso!')

        this.confirmDialogItem = {}
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    getRepresentantesTerritoriais: async function () {
      try {
        await this.getRepresentantesTerritoriaisByRepresentantePublicID(
          this.representantePublicID
        )
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>