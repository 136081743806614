<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Representante Territorial">
          <cadastrar-representante-territorial
            v-if="acao === 'cadastrar'"
            @close="changeAction"
            :representantePublicID="representantePublicID" />
          <editar-representante-territorial
            v-else-if="acao === 'Editar'"
            @close="changeAction"
            :representanteTerritorialASerEditado="
              representanteTerritorialASerEditado
            " />
          <listar-representante-territorial
            @cadastrar="changeAction"
            @editar="editar"
            :representantePublicID="representantePublicID"
            v-else-if="acao === 'listar'"
        /></BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import ListarRepresentanteTerritorial from '@/components/patio/RepresentanteTerritorial/ListarRepresentanteTerritorial'
import EditarRepresentanteTerritorial from '@/components/patio/RepresentanteTerritorial/EditarRepresentanteTerritorial'
import CadastrarRepresentanteTerritorial from '@/components/patio/RepresentanteTerritorial/CadastrarRepresentanteTerritorial'

export default {
  name: 'RepresentanteTerritorial',

  props: {
    representantePublicID: String,
  },

  components: {
    ListarRepresentanteTerritorial,
    EditarRepresentanteTerritorial,
    CadastrarRepresentanteTerritorial,
  },

  data: function () {
    return {
      page: {
        title: 'Gerenciamento do Representante Territorial',
      },
      breadcrumbs: [
        {
          text: 'Logística',
          disabled: true,
        },
        {
          text: 'Representante Territorial',
          disabled: false,
        },
      ],
      // cadastrar, listar, editar
      acao: 'listar',
      representanteTerritorialASerEditado: {},
    }
  },

  methods: {
    changeAction: function (acao = 'listar') {
      this.acao = acao
    },
    editar: function (representante) {
      this.acao = 'Editar'
      this.representanteTerritorialASerEditado = representante
    },
  },
}
</script>
