<template>
  <v-container>
    <div>
      <v-card-title class="justify-center">Adicionar Regiões</v-card-title>
      <form-regioes
        :representanteTerritorial="representanteTerritorial"
        :representantePublicID="representantePublicID"
        ref="formRegioes"
      />
      <v-card-actions class="justify-end">
        <buttons-cadastro @close="close" @save="save" value="Adicionar" />
      </v-card-actions>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

import FormRegioes from '../Representante/form/FormRegioes.vue'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'

export default {
  name: 'CadastrarRepresentanteTerritorial',

  props: {
    representantePublicID: {
      type: String,
      required: true,
    },
  },

  components: {
    FormRegioes,
    ButtonsCadastro,
  },

  data: function () {
    return {
      hasError: false,

      representanteTerritorial: {},
      form: {
        regioes: [],
      },
    }
  },

  methods: {
    ...mapActions('patio', ['cadastrarRepresentanteTerritorial']),
    ...mapActions('template', ['successMessage', 'errorMessage']),

    close: function () {
      this.$emit('close')
    },
    save: async function () {
      this.formatRepresentanteTerritorialFormBeforeSubmit()

      if (await this.$refs.formRegioes.validate()) {
        try {
          await this.cadastrarRepresentanteTerritorial({
            representante: this.representantePublicID,
            ...this.form,
          })

          this.successMessage('Regiões adicionadas com sucesso!')
          this.close()
        } catch (e) {
          this.errorMessage(e.response.data)
        }
      }
    },

    formatRepresentanteTerritorialFormBeforeSubmit: function () {
      this.form.regioes = this.representanteTerritorial.regioes.map(
        (regiao) => ({
          regiao: regiao.sigla,
          tipo_frete: regiao.fretesSelecionados,
        })
      )
    },
  },
}
</script>
