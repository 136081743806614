<template>
  <v-container>
    <v-card-title class="justify-center">Editar Representação</v-card-title>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form>
        <v-row justify="center">
          <v-col cols="6">
            <validation-provider>
              <v-text-field
                v-model="
                  representanteTerritorial.representante.colaborador.user.cpf
                "
                v-mask="['###.###.###-##']"
                label="CPF"
                prepend-icon="mdi-card-account-details"
                :hint="`Representante ${representanteTerritorial.representante.colaborador.user.name}`"
                persistent-hint
                readonly
                disabled
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <validation-provider name="regiao">
              <v-text-field
                v-model="representanteTerritorial.regiao.sigla"
                label="Região"
                prepend-icon="mdi-earth"
                readonly
                disabled
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="tipo frete"
              rules="required"
              vid="tipoFrete"
              ref="tipoFrete"
            >
              <v-select
                v-model="representanteTerritorial.tipo_frete"
                :items="representanteTerritorial.regiao.tipo_frete"
                :error-messages="errors"
                label="Tipo de Frete"
                prepend-icon="mdi-truck"
                multiple
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <v-card-actions>
              <v-spacer />
              <buttons-cadastro
                :disabled="invalid"
                value="Atualizar"
                @save="save"
                @close="$emit('close')"
              />
            </v-card-actions>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'

export default {
  name: 'EditarRepresentanteTerritorial',

  props: {
    representanteTerritorialASerEditado: {
      type: Object,
      required: true,
    },
  },

  components: { ButtonsCadastro },

  data: function () {
    return {
      representanteTerritorial: { ...this.representanteTerritorialASerEditado },
    }
  },

  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('patio', ['atualizarRepresentanteTerritorial']),

    close: function () {
      this.$emit('close')
    },
    save: async function () {
      if (this.validate()) {
        try {
          await this.atualizarRepresentanteTerritorial(
            this.representanteTerritorial
          )
          this.successMessage('Representação atualizada com sucesso!')
          this.close()
        } catch (e) {
          this.errorMessage(e.response.data)
        }
      }
    },

    validate: function () {
      const regiaoSigla = this.representanteTerritorial.regiao.sigla
      const fretesDaRegiao = this.representanteTerritorial.regiao.tipo_frete
      const fretesSelecionados = this.representanteTerritorial.tipo_frete

      fretesSelecionados.forEach((freteSelecionado) => {
        if (!fretesDaRegiao.includes(freteSelecionado)) {
          this.$refs.observer.setErrors({
            tipoFrete: `A região ${regiaoSigla} não permite o frete ${freteSelecionado}`,
          })
        }
      })

      return !this.$refs.tipoFrete.errors.length
    },
  },
}
</script>